<template>
    <calculator-roller
        :url="url"
        :token="token"
        :auth_token="auth_token"
        :coupon_config="coupon_config"
        :duration="duration"
        :residual="residual"
        :with-social="with_social"
        :all_round_protection="all_round_protection"
        :calculator_color="calculator_color"
        :employer_grant="employer_grant"
        :color_button="color_button"
        :portal="portal"
    ></calculator-roller>
</template>

<script>
import CalculatorRoller from '../CalculatorRoller.vue'

export default {
    components: {
        CalculatorRoller,
    },
    props: {
        url: {
            type: String,
            default: '',
        },
        token: {
            type: String,
            default: '',
        },
        auth_token:{
            type: String,
            default: ''
        },
        coupon_config: {
            type: String,
            default: '',
        },
        calculator_color: {
            type: String,
            default: 'yellow',
        },
        employer_grant: {
            type: String,
            default:
                '{"protection":{"value":1,"disabled":false},"leasing":{"value":0,"disabled":false},"package":{"value":1,"disabled":false},"tax":{"value":0,"disabled":false}}',
        },
        color_button: {
            type: String,
            default: '{"color":{"background":"#ffd400","text":"#000000"}}',
        },
        duration: {
            type: String,
            default: '36',
        },
        residual: {
            type: String,
            default: '18',
        },
        with_social: {
            type: String,
            default: '1',
        },
        all_round_protection: {
            type: String,
            default: '25',
        },
        portal: {
            type: Boolean,
            default: false
        }
    },
}
</script>

<style>
@import '../../../webcomponent/calculator-velo.css';
@import '../../../css/calculator.css';
</style>
