<template>
    <calculator-wrapper
        :tabs="tabs"
        :url="url"
        :settings="settings"
        :portal="portal"
    ></calculator-wrapper>
</template>

<script>

import CalculatorWrapper from '../../Calculators/CalculatorWrapper.vue'

export default {
    name: 'Wrapper',
    components: {
        CalculatorWrapper,
    },
    props: {
        tabs: {
            default: '',
            type: String,
        },
        url: {
            type: String,
            default: '',
        },
        settings: {
            type: String,
            default: '',
        },
        portal: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style>
@import '../../../webcomponent/calculator-velo.css';
@import '../../../css/calculator.css';
</style>
