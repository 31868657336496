<template>
    <div class="popup">
        <div class="popup-inner relative rounded-lg text-left">
            <button
                class="popup-close absolute focus:outline-none"
                @click="emitCloseButton()"
                style="left: 92%; top: 5px"
            >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.75691 16.2426L16.2422 7.75732"
                        stroke="#1F1F1F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M7.75691 7.75739L16.2422 16.2427"
                        stroke="#1F1F1F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5Z"
                        stroke="#1F1F1F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </button>
            <h2 class="relative">Info:</h2>
            <p>
                Da die Anfahrtskilometer zwischen<br />
                Wohnung und erster Arbeitsstelle zu <br />
                versteuern sind, benötigen wir diese Daten <br />
                für die Berechnung des geldwerten Vorteils.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    // props: ['togglePopup'],
    methods: {
        emitCloseButton() {
            this.$emit('toggle-popup')
        },
    },
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-inner {
        background: #fff;
        padding: 20px;
        .popup-close {
            right: 6px;
        }
    }
}
</style>