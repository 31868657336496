export default {
    mpp: {
        defaults: {
            salary: {
                age: 30,
                child: 0,
                childtax: 0,
                churchtax: 1,
                employercontribution: 0,
                grossmonthlysalary: '3000',
                healthinsurance: 1,
                healthinsurancecontribution: 0.0,
                pensioninsurance: 1,
                privatehealthinsurancecontribution: 0,
                state: 'BW',
                taxclass: 1,
                calcAGRS: 1,
                taxdeduction: false,
                calcType: 'MPP',
                duration: 24,
                all_round_protection: 18,
                grant_type: '',
                grant_fixvalue: 0,
                grant_percentage: 0,
            },
            products: {
                id: 1,
                price: 3000,
            },
        },
        budget: {
            min: 250,
            max: 25000,
            vat: 1.19
        },
    },
    velo: {
        defaults: {
            prodMSRP: '3000',
            coupon:{
                12: {
                    text: "ohne",
                    value: 12
                },
                14: {
                    text: "100€",
                    value: 14
                },
                21: {
                    text: "200€",
                    value: 21
                },
                23: {
                    text: "300€",
                    value: 23
                }
            },
            salary: {
                age: 30,
                child: 0,
                childtax: 0,
                churchtax: 1,
                employercontribution: 0,
                grossmonthlysalary: '3000',
                healthinsurance: 1,
                healthinsurancecontribution: '1.5',
                pensioninsurance: 1,
                privatehealthinsurancecontribution: 0,
                pecuniary_advantage: 1,
                duration: 36,
                state: 'BW',
                taxclass: 1,
                taxdeduction: '1',
                calcAGRS: 1,
                grant_percentage: '0',
                grant_fixvalue: 0,
                grant_service: 0,
                grant_type: '',
                calcType: 'Velo',
                service: 12,
            },
            products: {
                id: 1,
                price: 3000,
                msrp: 3000,
            },
        },
        budget: {
            min: 500,
            max: 10000,
            vat: 1.19
        },
    },
    pedelec: {
        defaults: {
            prodMSRP: '3000',
            coupon:{
                12: {
                    text: "ohne",
                    value: 12
                },
                21: {
                    text: "200€",
                    value: 21
                },
                23: {
                    text: "300€",
                    value: 23
                }
            },
            salary: {
                age: 30,
                child: 0,
                childtax: 0, //Kinderfreibetrag (Value 0-6)
                churchtax: 1, //Kirchensteuerpflicht (Value 0 ,1)
                distance: 20, //Entfernung
                duration: 36,
                employercontribution: 0,
                grossmonthlysalary: '3000', //Bruttogehalt
                healthinsurance: 1, //Krankenversicherungspflicht (Value 0, 1)
                healthinsurancecontribution: 1.5, //Zusatz Krankenkasse
                pensioninsurance: 1, //Rentenversicherung (Value 0, 1)
                privatehealthinsurancecontribution: 0,
                state: 'BW', //Bundesland
                taxclass: 1, //Steuerklasse (Value 1-6)
                taxdeduction: '1', //Arbeitgeber vorsteuerabzugsberechtigt (Value true, false)
                with_social: 1,
                calcAGRS: 1, //Übernahme RundumSchutz (Value 0 1)
                grant_percentage: 0, //Zuschuss in %
                grant_service: 0, //Übernahme Service-Gutschein
                grant_type: '',
                grant_fixvalue: 0,
                calcType: 'SPedelec',
                service: 12
            },
            products: {
                id: 1,
                price: 3000,
                msrp: 3000,
            },
        },
        budget: {
            min: 500,
            max: 10000,
            vat: 1.19
        },
    },
    roller: {
        defaults: {
            prodMSRP: '3000',
            coupon:{
                12: {
                    text: "ohne",
                    value: 12
                },
                21: {
                    text: "200€",
                    value: 21
                },
                23: {
                    text: "300€",
                    value: 23
                }
            },
            salary: {
                age: 30,
                child: 0,
                childtax: 0, //Kinderfreibetrag (Value 0-6)
                churchtax: 1, //Kirchensteuerpflicht (Value 0 ,1)
                distance: 20, //Entfernung
                duration: 36,
                employercontribution: 0,
                grossmonthlysalary: '3000', //Bruttogehalt
                healthinsurance: 1, //Krankenversicherungspflicht (Value 0, 1)
                healthinsurancecontribution: 1.5, //Zusatz Krankenkasse
                pensioninsurance: 1, //Rentenversicherung (Value 0, 1)
                privatehealthinsurancecontribution: 0,
                state: 'BW', //Bundesland
                taxclass: 1, //Steuerklasse (Value 1-6)
                taxdeduction: '1', //Arbeitgeber vorsteuerabzugsberechtigt (Value true, false)
                with_social: 1,
                calcAGRS: 1, //Übernahme RundumSchutz (Value 0 1)
                grant_percentage: 0, //Zuschuss in %
                grant_service: 0, //Übernahme Service-Gutschein
                grant_type: '',
                grant_fixvalue: 0,
                calcType: 'Roller',
                service: 12
            },
            products: {
                id: 1,
                price: 3000,
                msrp: 3000,
            },
        },
        budget: {
            min: 500,
            max: 10000,
            vat: 1.19
        },
    },
    scooter: {
        defaults: {
            prodMSRP: '3000',
            coupon:{
                12: {
                    text: "ohne",
                    value: 12
                },
                13: {
                    text: "70€",
                    value: 13
                },
                20: {
                    text: "150€",
                    value: 20
                }
            },
            salary: {
                age: 30,
                child: 0,
                childtax: 0, //Kinderfreibetrag (Value 0-6)
                churchtax: 1, //Kirchensteuerpflicht (Value 0 ,1)
                distance: 20, //Entfernung
                duration: 36,
                employercontribution: 0,
                grossmonthlysalary: '3000', //Bruttogehalt
                healthinsurance: 1, //Krankenversicherungspflicht (Value 0, 1)
                healthinsurancecontribution: 1.5, //Zusatz Krankenkasse
                pensioninsurance: 1, //Rentenversicherung (Value 0, 1)
                privatehealthinsurancecontribution: 0,
                state: 'BW', //Bundesland
                taxclass: 1, //Steuerklasse (Value 1-6)
                taxdeduction: '1', //Arbeitgeber vorsteuerabzugsberechtigt (Value true, false)
                with_social: 1,
                calcAGRS: 1, //Übernahme RundumSchutz (Value 0 1)
                grant_percentage: 0, //Zuschuss in %
                grant_service: 0, //Übernahme Service-Gutschein
                grant_type: '',
                grant_fixvalue: 0,
                calcType: 'Scooter',
                service: 12,
            },
            products: {
                id: 1,
                price: 3000,
                msrp: 3000,
            },
        },
        budget: {
            min: 500,
            max: 10000,
            vat: 1.19
        },
    },
}
