import { render, staticRenderFns } from "./CalculatorWrapper.vue?vue&type=template&id=049d3ace"
import script from "./CalculatorWrapper.vue?vue&type=script&lang=js"
export * from "./CalculatorWrapper.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports