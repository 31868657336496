<template>
    <div class="mx-2 mt-0" ref="elements">
        <div class="container bg-gray-200 rounded-lg" :class="{ loading: isloading }">
            <div class="container p-4">
                <div class="grid grid-cols-1 gap-4 mb-4">
                    <div class="relative flex flex-row sm:flex-col">
                        <label class="flex w-1/3 sm:w-full"
                            >Entfernung zum Arbeitsplatz (km)

                            <button
                                @click="toggleInfoPopup"
                                class="absolute sm:static focus:outline-none"
                                style="width: 18px; right: 10px; margin-left: 10px"
                                @mouseenter="toggleSvgVisibility"
                                @mouseleave="toggleSvgVisibility"
                            >
                                <svg
                                    v-if="showSvg"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="9" cy="9" r="8.5" stroke="black" />
                                    <circle cx="9" cy="4.5" r="1" fill="#1F1F1F" stroke="#1F1F1F" />
                                    <rect x="8" y="8" width="2" height="7" fill="black" />
                                </svg>
                                <svg
                                    :class="`calculator-${calculator_color}`"
                                    v-else
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle
                                        cx="9"
                                        cy="9"
                                        r="8.5"
                                        fill="currentColor"
                                        stroke="white"
                                    />
                                    <circle cx="9" cy="4.5" r="1" fill="white" stroke="white" />
                                    <rect x="8" y="8" width="2" height="7" fill="white" />
                                </svg>
                            </button>
                        </label>
                        <Infopopup v-if="buttonTrigger" @toggle-popup="toggleInfoPopup">
                        </Infopopup>
                        <input
                            type="number"
                            name=""
                            placeholder="30"
                            v-model="formdata.salary.distance"
                            @change="sendData"
                            class="w-1/2 py-1 font-bold text-center sm:w-full"
                        />
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-4 mb-4 lg:grid-cols-2">
                    <div class="flex flex-row sm:flex-col">
                        <label class="w-1/3 sm:w-full">Kaufpreis (brutto)</label>
                        <input
                            type="number"
                            name="purchaseprice"
                            placeholder="z.B.: 2000"
                            v-model="formdata.products.price"
                            @change="sendData"
                            class="w-1/2 py-1 font-bold text-center sm:w-full"
                            required
                            @input="
                                () => {
                                    if (formdata.products.price < 0) {
                                        formdata.products.price = 0
                                    }
                                }
                            "
                        /><span v-if="!isBudgetAndValid" class="text-red-600">{{
                            budgetMessage
                        }}</span>
                    </div>
                    <div class="flex flex-row sm:flex-col">
                        <label class="w-1/3 sm:w-full">UVP</label>
                        <input
                            type="number"
                            name="uvp"
                            v-model="formdata.products.msrp"
                            @change="sendData"
                            @input="
                                () => {
                                    if (formdata.products.msrp < 0) {
                                        formdata.products.msrp = 0
                                    }
                                }
                            "
                            class="w-1/2 py-1 font-bold text-center sm:w-full"
                        />
                        <span class="text-red-600">{{ priceMessage }}</span>
                    </div>
                    <div class="flex flex-row sm:flex-col">
                        <label class="w-1/3 sm:w-full">Bruttogehalt</label>
                        <input
                            type="number"
                            name="grosssalary"
                            placeholder="3000"
                            v-model="formdata.salary.grossmonthlysalary"
                            @change="sendData"
                            class="w-1/2 py-1 font-bold text-center sm:w-full"
                            @input="
                                () => {
                                    if (formdata.salary.grossmonthlysalary < 0) {
                                        formdata.salary.grossmonthlysalary = 0
                                    }
                                }
                            "
                        />
                        <span class="text-red-600">{{ errorMessageGross }}</span>
                    </div>
                    <div class="space-y-2 col-span-full lg:col-span-1 sm:space-y-0">
                        <label class="pb-2 sm:pb-0">Steuerklasse</label>
                        <div
                            :class="`radio-toolbar-${calculator_color}`"
                            class="flex flex-row -ml-2 space-x-2 radio-toolbar sm:space-x-4 sm:-ml-4"
                        >
                            <template v-for="taxClass in taxClasses">
                                <input
                                    type="radio"
                                    :id="`class${taxClass}`"
                                    class="rounded-full "
                                    v-model="formdata.salary.taxclass"
                                    @change="sendData"
                                    name="roller-tax-class"
                                    :value="taxClass"
                                />
                                <label class="cursor-pointer" :for="`class${taxClass}`">{{taxClass}}</label>
                            </template>

                        </div>
                    </div>

                    <div v-show="!portal" :class="isPortal" class="col-span-1 space-y-2 sm:space-y-0">
                        <label class="pb-2 sm:pb-0">Übernahme des Rundum-Schutzes</label>
                        <div
                            :class="`radio-toolbar-${calculator_color}`"
                            class="flex flex-row -ml-6 space-x-6 radio-toolbar"
                        >
                            <input
                                type="radio"
                                id="arbeitnehmer"
                                v-model="formdata.salary.calcAGRS"
                                @change="sendData"
                                name="roller-calcAGRS"
                                value="0"
                                :disabled="grantConfigObjects.protection.disabled"
                            />
                            <label for="arbeitnehmer">Arbeitnehmer</label>
                            <input
                                type="radio"
                                v-model="formdata.salary.calcAGRS"
                                @change="sendData"
                                id="arbeitgeber"
                                name="roller-calcAGRS"
                                value="1"
                                :disabled="grantConfigObjects.protection.disabled"
                            />
                            <label for="arbeitgeber">Arbeitgeber</label>
                        </div>
                    </div>
                    <div v-show="!portal"
                        :class="[isPortal, toggleGrantType]"
                        class="col-span-1 space-y-2 sm:space-y-0"
                    >
                        <label class="pb-2 sm:pb-0">Art des Zuschusses</label>
                        <div
                            :class="`radio-toolbar-${calculator_color}`"
                            class="flex flex-row -ml-6 space-x-6 radio-toolbar"
                        >
                            <input
                                type="radio"
                                id="EUR"
                                v-model="formdata.salary.grant_type"
                                @change="sendData"
                                name="roller-grant_type"
                                value="EUR"
                            />
                            <label for="EUR">Fester Betrag</label>
                            <input
                                type="radio"
                                v-model="formdata.salary.grant_type"
                                @change="sendData"
                                id="percent"
                                name="roller-grant_type"
                                value="percent"
                            />
                            <label for="percent">Prozentualer Zuschuss</label>
                        </div>
                    </div>
                    <div v-show="!portal"
                        :class="[isPortal, grantType == 'percent' ? '' : 'hidden']"
                        class="lg:col-start-2 radio-toolbar-number"
                    >
                        <p>Arbeitgeberzuschuss zur Leasingrate in %</p>
                        <input
                            type="number"
                            min="0"
                            max="100"
                            name="arbeitgeberzuschuss"
                            v-model="formdata.salary.grant_percentage"
                            @change="sendData"
                            @input="
                                () => {
                                    if (formdata.salary.grant_percentage > 100) {
                                        formdata.salary.grant_percentage = 100
                                    }
                                    if (formdata.salary.grant_percentage > 0) {
                                        formdata.salary.grant_fixvalue = 0
                                    }
                                }
                            "
                            placeholder="10"
                            class="w-full py-1 font-bold text-center"
                            required
                            :disabled="grantConfigObjects.leasing.disabled"
                        />
                        <span class="text-red-600">{{ errorMessagePercent }}</span>
                    </div>
                    <div v-show="!portal"
                        :class="[isPortal, grantType == 'EUR' ? '' : 'hidden']"
                        class="lg:col-start-2 radio-toolbar-number"
                    >
                        <p>Arbeitgeberzuschuss zur Leasingrate in €</p>
                        <input
                            type="number"
                            min="0"
                            max="1000"
                            name="arbeitgeberzuschuss"
                            v-model="formdata.salary.grant_fixvalue"
                            @change="sendData"
                            @input="
                                () => {
                                    if (formdata.salary.grant_fixvalue > 0) {
                                        formdata.salary.grant_percentage = 0
                                    }
                                }
                            "
                            placeholder="30"
                            class="w-full py-1 font-bold text-center"
                            required
                            :disabled="
                                grantConfigObjects.fixed && grantConfigObjects.fixed.disabled
                            "
                        />
                        <span class="text-red-600">{{ errorMessageFixed }}</span>
                    </div>
                    <div class="relative flex justify-center w-full mt-6 mb-4 sm:hidden">
                        <div class="w-full border-b-2 border-white"></div>
                        <button
                            @click="showFurtherFields()"
                            class="absolute flex content-center px-2 py-1 text-xl text-white transform -translate-y-1/2 bg-white rounded-full sm:px-20"
                        >
                            <span v-if="showFields" class="pt-1 pr-2 text-base text-black"
                                >Erweiterte Angaben verbergen</span
                            >
                            <span v-if="!showFields" class="pt-1 pr-2 text-base text-black"
                                >Erweiterte Angaben anzeigen</span
                            >
                            <svg
                                v-if="!showFields"
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-8 h-6 text-white"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M19 9l-7 7-7-7"
                                />
                            </svg>
                            <svg
                                v-if="showFields"
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-8 h-6 text-white"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 15l7-7 7 7"
                                />
                            </svg>
                        </button>
                    </div>
                    <div
                        :class="{ hidden: !showFields }"
                        class="grid-cols-1 gap-4 mb-4 space-y-3 col-span-full sm:grid lg:grid-cols-2 sm:space-y-0"
                    >
                        <div class="col-span-full">
                            <label>Bundesland</label>
                            <select
                                type="text"
                                name="state"
                                v-model="formdata.salary.state"
                                @change="sendData"
                                class="w-full py-2 text-center"
                            >
                                <option value="BW">Baden-Württemberg</option>
                                <option value="BY">Bayern</option>
                                <option value="BE">Berlin</option>
                                <option value="BB">Brandenburg</option>
                                <option value="HB">Bremen</option>
                                <option value="HH">Hamburg</option>
                                <option value="HE">Hessen</option>
                                <option value="MV">Mecklenburg-Vorpommern</option>
                                <option value="NI">Niedersachsen</option>
                                <option value="NW">Nordrhein-Westfalen</option>
                                <option value="RP">Rheinland-Pfalz</option>
                                <option value="SL">Saarland</option>
                                <option value="SN">Sachsen</option>
                                <option value="ST">Sachsen-Anhalt</option>
                                <option value="SH">Schleswig-Holstein</option>
                                <option value="TH">Thüringen</option>
                            </select>
                        </div>
                        <div>
                            <label>Zusatzbeitrag Krankenkasse</label>
                            <select
                                type="text"
                                name="healthinsurance_amount"
                                v-model="formdata.salary.healthinsurancecontribution"
                                @change="sendData"
                                class="flex justify-center w-full py-2 font-bold"
                            >
                                <option value="0">0,0%</option>
                                <option value="0.1">0,1%</option>
                                <option value="0.2">0,2%</option>
                                <option value="0.3">0,3%</option>
                                <option value="0.4">0,4%</option>
                                <option value="0.5">0,5%</option>
                                <option value="0.6">0,6%</option>
                                <option value="0.7">0,7%</option>
                                <option value="0.8">0,8%</option>
                                <option value="0.9">0,9%</option>
                                <option value="1">1,0%</option>
                                <option value="1.1">1,1%</option>
                                <option value="1.2">1,2%</option>
                                <option value="1.3">1,3%</option>
                                <option value="1.4">1,4%</option>
                                <option value="1.5">1,5%</option>
                                <option value="1.6">1,6%</option>
                                <option value="1.7">1,7%</option>
                                <option value="1.8">1,8%</option>
                                <option value="1.9">1,9%</option>
                            </select>
                        </div>
                        <div class="self-end">
                            <label>Kinderfreibetrag</label>
                            <select
                                type="text"
                                name="taxclass"
                                v-model="formdata.salary.childtax"
                                @change="sendData"
                                class="w-full py-2 font-bold text-center"
                            >
                                <option value="0">0</option>
                                <option value="0.5">0,5</option>
                                <option value="1">1</option>
                                <option value="1.5">1,5</option>
                                <option value="2">2</option>
                                <option value="2.5">2,5</option>
                                <option value="3">3</option>
                                <option value="3.5">3,5</option>
                                <option value="4">4</option>
                                <option value="4.5">4,5</option>
                                <option value="5">5</option>
                                <option value="5.5">5,5</option>
                                <option value="6">6</option>
                            </select>
                        </div>
                        <div v-show="!portal" :class="isPortal" class="col-span-1 space-y-2 sm:space-y-0">
                            <label class="pb-2 sm:pb-0">Übernahme des Service-Pakets</label>
                            <div
                                :class="`radio-toolbar-${calculator_color}`"
                                class="flex flex-row -ml-6 space-x-6 radio-toolbar"
                            >
                                <input
                                    type="radio"
                                    id="service_arbeitnehmer"
                                    v-model="formdata.salary.grant_service"
                                    @change="sendData"
                                    name="roller-grant_service"
                                    value="0"
                                    :disabled="grantConfigObjects.package.disabled"
                                />
                                <label for="service_arbeitnehmer">Arbeitnehmer</label>
                                <input
                                    type="radio"
                                    v-model="formdata.salary.grant_service"
                                    @change="sendData"
                                    id="service_arbeitgeber"
                                    name="roller-grant_service"
                                    value="1"
                                    :disabled="grantConfigObjects.package.disabled"
                                />
                                <label for="service_arbeitgeber">Arbeitgeber</label>
                            </div>
                        </div>
                        <div class="col-start-2">
                            <label>Service-Paket</label>
                            <div
                                :class="`radio-toolbar-${calculator_color}`"
                                class="flex flex-row -ml-2 space-x-2 radio-toolbar md:space-x-6 md:-ml-6"
                            >
                                <template v-for="couponObject in coupons">
                                    <input
                                        :key="couponObject.value"
                                        type="radio"
                                        :id="couponObject.text"
                                        v-model="selectedCoupon"
                                        @change="sendData"
                                        name="roller-gift"
                                        :value="couponObject.value"
                                        :disabled="coupons.length == 1"
                                    />
                                    <label :key="couponObject.text" :for="couponObject.text">{{
                                        couponObject.text
                                    }}</label>
                                </template>
                            </div>
                        </div>
                        <div class="flex flex-row col-start-1">
                            <div
                                :class="`checkbox-toolbar-${calculator_color}`"
                                class="w-1/6 checkbox-toolbar"
                            >
                                <input
                                    type="checkbox"
                                    id="churchtax"
                                    class="rounded-full"
                                    v-model="formdata.salary.churchtax"
                                    @change="sendData"
                                    name="ischurchtax"
                                    :true-value="1"
                                    :false-value="0"
                                />
                                <label v-if="formdata.salary.churchtax == '0'" for="churchtax"
                                    >X</label
                                >
                                <label v-if="formdata.salary.churchtax == '1'" for="churchtax"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center">Kirchensteuerpflicht</span>
                        </div>
                        <div class="flex flex-row">
                            <div
                                :class="`checkbox-toolbar-${calculator_color}`"
                                class="w-1/6 checkbox-toolbar"
                            >
                                <input
                                    type="checkbox"
                                    id="pensiontax"
                                    class="rounded-full"
                                    v-model="formdata.salary.pensioninsurance"
                                    @change="sendData"
                                    name="ispensiontax"
                                    :true-value="1"
                                    :false-value="0"
                                />
                                <label
                                    v-if="formdata.salary.pensioninsurance == '0'"
                                    for="pensiontax"
                                    >X</label
                                >
                                <label
                                    v-if="formdata.salary.pensioninsurance == '1'"
                                    for="pensiontax"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center">Rentenversicherungspflicht</span>
                        </div>
                        <div class="flex flex-row col-start-1">
                            <div
                                :class="`checkbox-toolbar-${calculator_color}`"
                                class="w-1/6 checkbox-toolbar"
                            >
                                <input
                                    type="checkbox"
                                    id="healthinsurance"
                                    class="rounded-full"
                                    v-model="formdata.salary.healthinsurance"
                                    @change="sendData"
                                    name="ishealthinsurance"
                                    :true-value="1"
                                    :false-value="0"
                                />
                                <label
                                    v-if="formdata.salary.healthinsurance == '0'"
                                    for="healthinsurance"
                                    >X</label
                                >
                                <label
                                    v-if="formdata.salary.healthinsurance == '1'"
                                    for="healthinsurance"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center">Krankenversicherungspflicht</span>
                        </div>
                        <div class="flex flex-row">
                            <div
                                :class="`checkbox-toolbar-${calculator_color}`"
                                class="w-1/6 checkbox-toolbar"
                            >
                                <input
                                    type="checkbox"
                                    id="pretax"
                                    class="rounded-full"
                                    v-model="formdata.salary.taxdeduction"
                                    @change="sendData"
                                    name="ispretax"
                                    :true-value="0"
                                    :false-value="1"
                                    :disabled="grantConfigObjects.tax.disabled"
                                />
                                <label v-if="formdata.salary.taxdeduction == '1'" for="pretax"
                                    >X</label
                                >
                                <label v-if="formdata.salary.taxdeduction == '0'" for="pretax"
                                    >✓</label
                                >
                            </div>
                            <span class="self-center"
                                >Ist Ihr Arbeitgeber vorsteuerabzugsberechtigt?</span
                            >
                        </div>
                    </div>
                </div>
                <div
                    v-if="successText"
                    class="grid content-center w-full grid-cols-1 p-2 sm:flex calculator-success rounded-xl sm:space-x-4"
                >
                    <svg
                        class="justify-self-center"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M19.4485 27.8545C19.4485 25.9916 17.9383 24.4814 16.0755 24.4814H11.8592C11.8592 22.6186 10.349 21.1084 8.48615 21.1084H2.58334V27.8545H19.4485Z"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M8.48615 24.4814H11.8592"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M14.3889 21.1084H25.3513C25.817 21.1084 26.1946 20.7308 26.1946 20.2651V12.6758"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M11.0159 12.6758V17.7353"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M9.32941 9.20947C9.32941 8.79526 9.66519 8.45947 10.0794 8.45947H27.1311C27.5453 8.45947 27.8811 8.79526 27.8811 9.20947V11.9258C27.8811 12.34 27.5453 12.6758 27.1311 12.6758H10.0794C9.66519 12.6758 9.32941 12.34 9.32941 11.9258V9.20947Z"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M18.6053 21.1083V8.45947"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.4006 6.07478C21.9876 7.18284 20.3653 7.99398 18.6311 8.45952C19.0966 6.72529 19.9078 5.10301 21.0158 3.69005C22.4988 2.20816 23.3387 2.43528 24.0021 3.09414C24.6655 3.75301 24.8836 4.5929 23.4006 6.07478Z"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.8088 6.07478C15.2219 7.18263 16.8441 7.99375 18.5783 8.45952C18.1133 6.7251 17.3021 5.10271 16.1935 3.69005C14.7117 2.20816 13.8718 2.43528 13.2084 3.09414C12.545 3.75301 12.3269 4.5929 13.8088 6.07478Z"
                            stroke="#46D390"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    <p v-html="successText" class="self-center"></p>
                </div>
            </div>
        </div>
        <div v-if="showError" class="text-center">
            <span class="text-red-600"
                >Die Berechnung kann nicht ausgeführt werden. Bitte überprüfe deine Eingaben</span
            >
        </div>
        <div class="container">
            <div class="grid grid-cols-1 py-4 text-center lg:grid-cols-2 lg:text-left">
                <div class="flex flex-col">
                    <p>So viel wird gespart</p>
                    <div>
                        <p class="text-5xl font-bold">
                            {{ getLocalePrice(productData.saving) }}
                        </p>
                        <div class="flex justify-center text-base lg:text-xl lg:justify-start">
                            <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M30.667 14.0444C30.667 16.2045 28.9159 17.9555 26.7559 17.9555"
                                    stroke="#FFD400"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M8.17794 9.22071C6.07427 10.5841 4.50791 12.6342 3.74535 15.0222H1.3335V22.8444H4.57972C5.25483 24.0281 6.14329 25.0766 7.20016 25.9368V29.6889C7.20016 30.2289 7.63793 30.6666 8.17794 30.6666H10.1335C10.6735 30.6666 11.1113 30.2289 11.1113 29.6889V28.071C13.6489 28.9195 16.3928 28.9245 18.9335 28.0853V29.6889C18.9335 30.2289 19.3713 30.6666 19.9113 30.6666H21.8668C22.4068 30.6666 22.8446 30.2289 22.8446 29.6889V25.9564C25.2874 24.0151 26.7243 21.0756 26.7557 17.9555C26.709 14.3999 24.8656 11.1096 21.8577 9.21289"
                                    stroke="#FFD400"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    d="M11.1113 10.1337C13.576 8.91377 16.4688 8.91377 18.9336 10.1337"
                                    stroke="#FFD400"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M15.0224 9.15572C17.1825 9.15572 18.9336 7.40465 18.9336 5.24461C18.9336 3.08456 17.1825 1.3335 15.0224 1.3335C12.8624 1.3335 11.1113 3.08456 11.1113 5.24461C11.1113 7.40465 12.8624 9.15572 15.0224 9.15572Z"
                                    stroke="#FFD400"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            <p class="pt-1 pl-3 text-2xl">
                                <strong>{{ productData.saving_percentage }}%</strong>
                                gespart!
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col pt-4 lg:pt-0">
                    <p>Die individuelle Nutzungsrate beträgt:</p>
                    <p class="text-5xl font-bold">
                        {{ getLocalePrice(productData.leasing_net) }}
                    </p>
                </div>
            </div>

            <div class="relative flex justify-center w-full my-12">
                <div class="w-full border-b-2 border-black"></div>
                <button
                    @click="showCalculation()"
                    :disabled="showError"
                    class="absolute flex content-center px-5 py-1 text-xl text-white transform -translate-y-1/2 bg-black rounded-full sm:px-20"
                >
                    <span v-if="showTable" style="line-height: 30px" class="pr-6"
                        >Berechnung anzeigen</span
                    >
                    <span v-if="!showTable" style="line-height: 30px" class="pr-6"
                        >Berechnung verbergen</span
                    >
                    <svg
                        v-if="showTable"
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-6 text-white"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                        />
                    </svg>
                    <svg
                        v-if="!showTable"
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-8 h-6 text-white"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 15l7-7 7 7"
                        />
                    </svg>
                </button>
            </div>

            <div v-if="!showTable" class="py-12" :class="{ loading: isloading }">
                <h2 class="py-6 text-2xl font-semibold">Errechnung der monatlichen Kosten:</h2>

                <table class="w-full py-4 text-lg table-fixed">
                    <thead>
                        <tr class="text-left border-b-4 border-black">
                            <th class="hidden w-1/2 sm:table-cell"></th>
                            <th class="flex px-1"><p class="self-start">ohne Dienstroller</p></th>
                            <th class="table-cell w-1/3 sm:hidden"></th>
                            <th class="px-1 text-right sm:text-left">
                                <p>mit Dienstroller</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Bruttomonatsgehalt</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p class="pt-2">
                                    {{ getLocalePrice(formdata.salary.grossmonthlysalary) }}
                                </p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Bruttomonatsgehalt</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>{{ getLocalePrice(formdata.salary.grossmonthlysalary) }}</p>
                            </td>
                        </tr>

                        <template v-for="amount in amounts">
                            <tr :key="amount.index" class="relative border-b-2 border-black">
                                <td class="hidden w-1/2 sm:table-cell">
                                    <p class="font-bold">{{ amount.label }}</p>
                                </td>
                                <td class="pt-10 pb-4 sm:pt-4">
                                    <p>0,00 €</p>
                                </td>
                                <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                    <p class="pt-2 mx-auto font-bold">{{ amount.label }}</p>
                                </td>
                                <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                    <p>{{ amount.text }}</p>
                                </td>
                            </tr>
                        </template>

                        <!-- <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Umwandlungsbetrag Leasingrate</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Umwandlungsbetrag Leasingrate</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>- {{ getLocalePrice(productData.leasing_gross) }}</p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Umwandlungsbetrag Rundum-Schutz</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">
                                    Umwandlungsbetrag Rundum-Schutz
                                </p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    - {{ getLocalePrice(productData.conversion_amount_insurance) }}
                                </p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Umwandlungsbetrag Service-Paket</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">
                                    Umwandlungsbetrag Service Paket
                                </p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>- {{ getLocalePrice(productData.service) }}</p>
                            </td>
                        </tr> -->
                        <!-- <tr
                            v-if="formdata.salary.grant_percentage > 0"
                            class="relative border-b-2 border-black"
                        >
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Arbeitgeber-Zuschuss</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Arbeitgeber-Zuschuss</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>+ {{ getLocalePrice(productData.grant) }}</p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Geldwerter Vorteil (0,25% Regel)</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">
                                    Geldwerter Vorteil (0,25% Regel)
                                </p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>+ {{ getLocalePrice(productData.pecuniary_advantage) }}</p>
                            </td>
                        </tr>
                        <tr class="relative border-b-4 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Versteuerung Anfahrtskilometer</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Versteuerung Anfahrtskilometer</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>+ {{ getLocalePrice(productData.travel_costs) }}</p>
                            </td>
                        </tr> -->
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Berechnungsgrundlage</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>{{ getLocalePrice(formdata.salary.grossmonthlysalary) }}</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Berechnungsgrundlage</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    {{ getLocalePrice(productData.calculation_base) }}
                                </p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Steuern gesamt</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>- {{ getLocalePrice(productDetails.tax_sum.gross) }}</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Steuern gesamt</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>- {{ getLocalePrice(productDetails.tax_sum.net) }}</p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Sozialabgaben gesamt</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>
                                    -
                                    {{
                                        getLocalePrice(
                                            productDetails.social_contributions_total.gross,
                                        )
                                    }}
                                </p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Sozialabgaben gesamt</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    -
                                    {{
                                        getLocalePrice(
                                            productDetails.social_contributions_total.net,
                                        )
                                    }}
                                </p>
                            </td>
                        </tr>

                        <template v-for="net_amount in net_amounts">
                            <tr :key="net_amount.index" class="relative border-b-2 border-black">
                                <td class="hidden w-1/2 sm:table-cell">
                                    <p class="font-bold">{{ net_amount.label }}</p>
                                </td>
                                <td class="pt-10 pb-4 sm:pt-4">
                                    <p>0,00 €</p>
                                </td>
                                <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                    <p class="pt-2 mx-auto font-bold">{{ net_amount.label }}</p>
                                </td>
                                <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                    <p>{{ net_amount.text }}</p>
                                </td>
                            </tr>
                        </template>

                        <!-- <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Geldwerter Vorteil (0,25% Regel)</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>- 0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">
                                    Geldwerter Vorteil (0,25% Regel)
                                </p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    -
                                    {{ getLocalePrice(productData.pecuniary_advantage) }}
                                </p>
                            </td>
                        </tr>
                        <tr class="relative border-b-4 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Versteuerung Anfahrtskilometer</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>0,00 €</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Versteuerung Anfahrtskilometer</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    -
                                    {{ getLocalePrice(productData.travel_costs) }}
                                </p>
                            </td>
                        </tr> -->
                        <tr class="relative">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Nettomonatsgehalt</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>
                                    {{ getLocalePrice(productDetails.salary.gross) }}
                                </p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Nettomonatsgehalt</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    {{ getLocalePrice(productDetails.salary.net) }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex flex-col py-4 text-center text-white bg-black">
                    <span>Differenz Nettomonatsgehalt = Individuelle Nutzungsrate:</span>
                    <span class="text-2xl font-bold">
                        {{ getLocalePrice(productData.leasing_net) }}
                    </span>
                </div>

                <h2 class="py-4 pt-16 text-2xl font-semibold">
                    So errechnet sich die Ersparnis zum Privatkauf:
                </h2>

                <table class="w-full text-lg table-fixed">
                    <thead>
                        <tr class="text-left border-b-4 border-black">
                            <th class="hidden w-1/2 sm:table-cell"></th>
                            <th>Privatkauf mit Schutz</th>
                            <th class="table-cell w-1/3 sm:hidden"></th>
                            <th class="px-1 text-xs text-right sm:text-left sm:text-lg">
                                <p class="font-normal">
                                    <strong class="text-lg">mit Dienstroller</strong><br />
                                    inkl. Rundum-Schutz
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">Roller</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>
                                    {{ getLocalePrice(formdata.products.price) }}
                                </p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">Roller</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    ({{ formdata.salary.duration }} Monate x
                                    {{ getLocalePrice(productData.leasing_net) }}) =
                                    {{ getLocalePrice(productData.leasing_net_total) }}
                                </p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">zzgl. Rundum-Schutz (Gehaltsumwandlung)</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>
                                    {{ getLocalePrice(productData.general_insurance) }}
                                </p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">
                                    zzgl. Rundum-Schutz (Gehaltsumwandlung)
                                </p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>inklusive</p>
                            </td>
                        </tr>
                        <tr
                            v-if="productData.general_service_costs > 0"
                            class="relative border-b-2 border-black"
                        >
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">zzgl. Service</p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>
                                    {{ getLocalePrice(productData.general_service_costs) }}
                                </p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">zzgl. Service</p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>inklusive</p>
                            </td>
                        </tr>
                        <tr class="relative border-b-2 border-black">
                            <td class="hidden w-1/2 sm:table-cell">
                                <p class="font-bold">
                                    Erwarteter Gebrauchtkaufpreis
                                    {{ productData.calculation_residual }}%
                                </p>
                            </td>
                            <td class="pt-10 pb-4 sm:pt-4">
                                <p>{{ getLocalePrice(0) }}</p>
                            </td>
                            <td class="absolute top-0 left-0 right-0 flex sm:hidden">
                                <p class="pt-2 mx-auto font-bold">
                                    Erwarteter Gebrauchtkaufpreis
                                    {{ productData.calculation_residual }}%
                                </p>
                            </td>
                            <td class="pt-10 pb-4 text-right align-bottom sm:pt-4 sm:text-left">
                                <p>
                                    {{ getLocalePrice(productData.general_rest) }}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex flex-col py-4 text-center text-white bg-black">
                    <span>Barkauf minus Dienstroller = Ersparnis von:</span>
                    <span class="text-2xl font-bold">
                        {{ getLocalePrice(productData.saving) }} ({{
                            productData.saving_percentage
                        }}%)
                    </span>
                </div>
                <div class="pt-4 pb-8">
                    <span
                        >Berechnung ohne Gewähr. Bitte wenden Sie sich an Ihren Steuerberater, um
                        eine verbindliche Kalkulation zu erhalten.</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import config from '../../config/calculator.config.js'
import { debounce } from 'lodash'
import axios from 'axios'
import Infopopup from './components/Infopopup.vue'

export default {
    components: { Infopopup },
    props: {
        url: {
            type: String,
            default: '',
        },
        token: {
            type: String,
            default: '',
        },
        auth_token: {
            type: String,
            default: '',
        },
        coupon_config: {
            type: String,
            default: '',
        },
        duration: {
            type: String,
            default: '36',
        },
        residual: {
            type: String,
            default: '18',
        },
        with_social: {
            type: String,
            default: '1',
        },
        all_round_protection: {
            type: String,
            default: '36',
        },
        calculator_color: {
            type: String,
            default: '',
        },
        employer_grant: {
            type: String,
            default: '',
        },
        color_button: {
            type: String,
            default: '',
        },
        portal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const formdata = {
            ...config.roller.defaults,
            salary: {
                ...config.roller.defaults.salary,
                grant_percentage: JSON.parse(this.employer_grant).leasing.value,
                // grant_fixvalue: this.grant_fixvalue_test,
                calcAGRS: JSON.parse(this.employer_grant).protection.value,
                grant_service: JSON.parse(this.employer_grant).package.value,
                taxdeduction: JSON.parse(this.employer_grant).tax.value,
                // service: Object.keys(JSON.parse(this.coupon))[0],
                // service_value_net: Object.values(JSON.parse(this.coupon))[0].value_net,
                // service_value: Object.values(JSON.parse(this.coupon))[0].value,
            },
            products: {
                id: config.roller.defaults.products.id,
                price: config.roller.defaults.products.price,
                msrp: config.roller.defaults.products.msrp,
            },
        }
        return {
            taxClasses: [1,2,3,4,5,6],
            buttonTrigger: false,
            showSvg: true,
            showError: false,
            showFields: false,
            showTable: true,
            isloading: false,
            vat: config.roller.budget.vat,
            maxValue: config.roller.budget.max * config.roller.budget.vat,
            minValue: config.roller.budget.min * config.roller.budget.vat,
            coupon: config.roller.defaults.coupon,
            formdata,
            calculatorValues: config.roller.budget,
            apiData: {},
            header: {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
                Accept: 'application/x.calculator.v1+json',
            },
            sendData: debounce(this.sendRequestToCalculator, 150),
            selectedCoupon: formdata.salary.service,
        }
    },
    beforeMount() {
        if (JSON.parse(this.employer_grant).fixed && JSON.parse(this.employer_grant).fixed.value) {
            this.formdata.salary.grant_fixvalue = JSON.parse(this.employer_grant).fixed.value
        } else {
            this.formdata.salary.grant_fixvalue = 0
        }
        if (this.coupon_config && this.coupon_config !== '') {
            this.formdata.salary.service = Object.keys(JSON.parse(this.coupon_config))[0]
            ;(this.formdata.salary.service_value_net = Object.values(
                JSON.parse(this.coupon_config),
            )[0].value_net),
                (this.formdata.salary.service_value = Object.values(
                    JSON.parse(this.coupon_config),
                )[0].value)
            this.selectedCoupon = this.formdata.salary.service

        } else {
            this.formdata.salary.service = 12
        }

        if (
            JSON.parse(this.employer_grant).fixed &&
            JSON.parse(this.employer_grant).fixed.value > 0
        ) {
            this.formdata.salary.grant_type = 'EUR'
        } else {
            this.formdata.salary.grant_type = 'percent'
        }

        const rootColor = document.querySelector(':root')
        rootColor.style.setProperty(
            '--calcColorButton',
            JSON.parse(this.color_button).color.background,
        )
        rootColor.style.setProperty('--calcColorText', JSON.parse(this.color_button).color.text)
    },
    computed: {
        toggleGrantType() {
            return (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0) ||
                this.grantConfigObjects.leasing.value > 0
                ? 'hidden'
                : ''
        },
        grantType() {
            return (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0) ||
                this.formdata.salary.grant_type == 'EUR'
                ? 'EUR'
                : 'percent'
        },
        grantPercentType() {
            return (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0) ||
                this.formdata.salary.grant_type == 'EUR'
                ? 'hidden'
                : ''
        },
        grantFixedType() {
            return this.grantConfigObjects.leasing.value > 0 ||
                (this.formdata.salary.grant_type == 'percent' &&
                    this.grantConfigObjects.fixed &&
                    this.grantConfigObjects.fixed.value == 0 &&
                    this.grantPercentType !== 'hidden')
                ? 'hidden'
                : ''
        },
        isPortal() {
            return this.portal == true ? 'hidden' : ''
        },
        couponObjects() {
            if (!this.coupon_config || this.coupon_config === '') {
                return this.coupon
            } else {
                return JSON.parse(this.coupon_config)
            }
        },
        grantConfigObjects() {
            return JSON.parse(this.employer_grant)
        },
        coupons() {
            return Object.entries(this.couponObjects).map(([key, obj]) => ({
                text: obj.text,
                value_net: obj.value_net,
                value: key,
            }))
        },
        errorMessagePercent(){
            return (this.formdata.salary.grant_percentage === '' && this.formdata.salary.grant_type == 'percent') ? 'Bitte geben Sie einen gültigen Wert an' : '';
        },
        errorMessageFixed(){
            return (this.formdata.salary.grant_fixvalue === '' && this.formdata.salary.grant_type == 'EUR') ? 'Bitte geben Sie einen gültigen Wert an' : '';
        },
        errorMessageGross(){
            return (this.formdata.salary.grossmonthlysalary === '' ) ? 'Bitte geben Sie einen gültigen Wert an' : '';
        },
        products() {
            return {
                // calculation: this.token,
                authorization: this.auth_token,
                salary: {
                    ...this.formdata.salary,
                    residual: this.residual,
                    duration: this.duration,
                    with_social: this.with_social,
                },
                products: {
                    1: {
                        ...this.formdata.products,
                    },
                },
            }
        },
        setGrantPercentage() {
            if (this.formdata.salary.grant_percentage >= 100) {
                this.formdata.salary.grant_percentage = 100
                return
            }
        },
        productData() {
            if (!this.apiData.products_calculated) {
                return {}
            }

            return this.apiData && this.apiData.products_calculated
                ? Object.keys(this.apiData.products_calculated[this.formdata.products.id])
                      .map((productPrice) => {
                          return this.apiData.products_calculated[this.formdata.products.id][
                              productPrice
                          ]
                      })
                      .pop()
                : {}
        },
        productDetails() {
            return this.productData.details
        },
        amounts() {
            return this.productData.leasing.gross
        },
        net_amounts() {
            return this.productData.leasing.net
        },
        hasProductData() {
            return this.productData ? Object.keys(this.productData).length > 0 : false
        },
        isValidated() {
            return (
                parseFloat(this.formdata.products.price) !== 0 &&
                parseFloat(this.formdata.products.price) > 0 &&
                parseFloat(this.formdata.salary.grossmonthlysalary) !== 0 &&
                parseFloat(this.formdata.salary.grossmonthlysalary) > 0
            )
        },
        isBudgetAndValid() {
            return (
                this.isBudget(this.formdata.products.price) &&
                this.isBudget(this.formdata.salary.grossmonthlysalary, 'brutto') &&
                this.isValidated
            )
        },
        budgetMessage() {
            if (this.formdata.products.price < this.minValue) {
                return `Der angegebene Preis ist zu niedrig. Dieser muss größer sein als ${this.getLocalePrice(
                    this.minValue,
                )}`
            } else if (this.formdata.products.price > this.maxValue) {
                return `Der angegebene Preis ist zu hoch. Dieser muss niedriger sein als ${this.getLocalePrice(
                    this.maxValue,
                )}`
            }
            return ''
        },
        priceMessage() {
            if (this.formdata.products.msrp < this.minValue) {
                return `Der angegebene Preis ist zu niedrig. Dieser muss größer sein als ${this.getLocalePrice(
                    this.minValue,
                )}`
            } else if (this.formdata.products.msrp > this.maxValue) {
                return `Der angegebene Preis ist zu hoch. Dieser muss niedriger sein als ${this.getLocalePrice(
                    this.maxValue,
                )}`
            }
            return ''
        },
        successText() {
            let getLeasingRate =
                (this.grantConfigObjects.leasing && this.grantConfigObjects.leasing.value > 0) || (this.grantConfigObjects.fixed && this.grantConfigObjects.fixed.value > 0)
                    ? '1'
                    : '0'
            let getGrantService =
                this.grantConfigObjects.package.disabled == true &&
                this.grantConfigObjects.package.value > 0
                    ? '1'
                    : '0'
            let getGrantRate =
                this.grantConfigObjects.protection.disabled == true &&
                this.grantConfigObjects.protection.value > 0
                    ? '1'
                    : '0'
            let agMessageArray = [getGrantRate, getLeasingRate, getGrantService]

            switch (agMessageArray.join(',')) {
                case '0,0,0':
                    return false
                    break
                case '1,0,0':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>schenkt dir den Rundum-Schutz.</strong> Die Summe dafür wird von deiner Nutzungsrate automatisch abgezogen.'
                    break
                case '0,1,0':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>bezuschusst die Leasingrate.</strong> Deine Nutzungsrate verringert sich in Höhe des Zuschusses.'
                    break
                case '1,1,0':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>schenkt dir den Rundum-Schutz und bezuschusst die Leasingrate.</strong>'
                    break
                case '1,0,1':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>schenkt dir den Rundum-Schutz und den Service Gutschein.</strong> Die Summe dafür wird von deiner Nutzungsrate automatisch abgezogen.'
                    break
                case '0,1,1':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>schenkt dir den Service Gutschein und bezuschusst die Leasingrate.</strong>'
                    break
                case '0,0,1':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>schenkt dir den Service Gutschein.</strong> Die Summe dafür wird von deiner Nutzungsrate automatisch abgezogen.'
                    break
                case '1,1,1':
                    return 'Glückwunsch! Dein Arbeitgeber <strong>schenkt dir den Rundum-Schutz, den Service Gutschein und bezuschusst die Leasingrate.</strong>'
                    break
                default:
                    return false
            }
        },
    },
    created() {
        this.formdata.salary.healthinsurancecontribution =
            this.formdata.salary.healthinsurancecontribution == 0
                ? '0.0'
                : this.formdata.salary.healthinsurancecontribution
    },
    watch: {
        selectedCoupon: {
            handler() {
                if (this.formdata.salary.service === this.selectedCoupon) {
                    return
                }
                this.formdata.salary.service = this.selectedCoupon
                this.formdata.salary.service_value_net =
                    this.couponObjects[this.selectedCoupon].value_net
                this.formdata.salary.service_value = this.couponObjects[this.selectedCoupon].value
            },
            immediate: true,
        },
    },
    methods: {
        toggleInfoPopup() {
            this.buttonTrigger = !this.buttonTrigger
            this.showSvg = true
        },
        toggleSvgVisibility() {
            this.showSvg = !this.showSvg
        },
        showFurtherFields() {
            this.showFields = !this.showFields
        },
        isBudget(price, field = '') {
            return (
                (field !== '' && parseFloat(price) >= parseFloat(this.minValue)) ||
                (parseFloat(price) >= parseFloat(this.minValue) &&
                    parseFloat(price) <= parseFloat(this.maxValue))
            )
        },

        showCalculation() {
            this.showTable = !this.showTable
        },
        async sendRequestToCalculator() {
            this.isloading = true
            if (
                !isNaN(this.formdata.products.price) &&
                this.formdata.products.price >= this.minValue &&
                this.formdata.products.price <= this.maxValue &&
                (this.formdata.salary.grossmonthlysalary !== '') &&
                (this.formdata.products.msrp !== '') &&
                (this.formdata.salary.grant_fixvalue !== '') &&
                (this.formdata.salary.grant_percentage !== '')
            ) {
                await axios
                    .post(
                        this.url + '/api/calculate?withoutHash=1',
                        { ...this.products },
                        { headers: this.header },
                    )
                    .then((data) => {
                        this.showError = false
                        this.apiData = data.data
                    })
                    .catch(() => {
                        this.showError = true
                    })
            }
        },
        getLocalePrice(price) {
            return price === undefined
                ? ''
                : new Intl.NumberFormat('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                  }).format(parseFloat(price.toString().split(',').join('.')))
        },
        customerHasChildren() {
            if (this.formdata.salary.child === 0) {
                this.formdata.salary.childtax = 0
            }
        },
        transformFormData(rep_prefix, prefix) {
            this.formdata.salary.grossmonthlysalary = this.formdata.salary.grossmonthlysalary
                .split(rep_prefix)
                .join(prefix)
            this.formdata.products.price = this.formdata.products.price
                .split(rep_prefix)
                .join(prefix)
        },
    },
    async mounted() {
        await this.sendRequestToCalculator()
    },
}
</script>

<style scoped>
select {
    text-align-last: center;
}

label {
    line-height: 1.5;
}
</style>
