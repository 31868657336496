<template>
    <div class="container mt-5">
        <div v-if="getTabs.length > 1" class="w-full">
            <div class="container px-12">
                <ul class="flex items-end justify-end w-full rounded-t-lg list-reset">
                    <li class="w-1/4 mr-1 sm:w-auto" v-for="item in getTabs">
                        <button
                            @click="getCalculator(item)"
                            :class="[productType === item ? 'bg-primary h-20' : 'bg-gray-200 h-18']"
                            class="flex items-end justify-center flex-1 w-full px-2 py-2 font-semibold sm:w-24 rounded-t-xl"
                        >
                            <component :is="`Icon` + item" />
                        </button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="container w-full px-4 pb-8 mx-auto">
            <keep-alive>
                <component
                    :is="`Calculator${productType.charAt(0).toUpperCase() + productType.slice(1)}`"
                    :token="token"
                    :auth_token="auth_token"
                    :url="url"
                    :coupon_config="coupon_config"
                    :calculator_color="calculator_color"
                    :employer_grant="employer_grant"
                    :color_button="color_button"
                    :config_token="config_token"
                    :residual="residual"
                    :grantType_config="grantType_config"
                    :portal="portal"
                ></component>
            </keep-alive>
        </div>
    </div>
</template>

<script>
import IconRoller from '../Base/Icons/IconRoller.vue'
import IconScooter from '../Base/Icons/IconScooter.vue'
import IconVelo from '../Base/Icons/IconVelo.vue'
import IconMpp from '../Base/Icons/IconMpp.vue'
import IconSPedelec from '../Base/Icons/IconSPedelec.vue'

import CalculatorVelo from './CalculatorVelo.vue'
import CalculatorMpp from './CalculatorMpp.vue'
import CalculatorScooter from './CalculatorScooter.vue'
import CalculatorRoller from './CalculatorRoller.vue'
import CalculatorSPedelec from './CalculatorSPedelec.vue'

export default {
    name: 'CalculatorWrapper',
    components: {
        IconSPedelec,
        IconMpp,
        IconVelo,
        IconRoller,
        IconScooter,
        CalculatorVelo,
        CalculatorMpp,
        CalculatorSPedelec,
        CalculatorScooter,
        CalculatorRoller,
    },
    created() {
        const showTabs = this.tabs.split(',')
        let type = showTabs[0]
        this.settingType = type.toLowerCase()
        this.tabType = type
        this.productType = type
    },
    methods: {
        getCalculator(item) {
            this.productType = item
            this.settingType = item.toLowerCase()
        },
        containsKey(obj, key) {
            return Object.keys(obj).includes(key)
        },
    },
    computed: {
        componentName() {
            if (typeof this.mode !== 'string') {
                return
            }

            return `Calculator${this.mode.charAt(0).toUpperCase() + this.mode.slice(1)}`
        },
        getTabs() {
            const showTabs = ['Mpp', 'Velo', 'SPedelec', 'Roller', 'Scooter']
            const givenTabs = this.tabs.split(',')
            const filteredArray = showTabs.filter((value) => givenTabs.includes(value))

            return filteredArray
        },

        token() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['token'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['token']
            }
            return ''
        },

        auth_token() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['auth_token'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['auth_token']
            }
            return ''
        },
        config_token() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['config_token'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['config_token']
            }
            return ''
        },
        coupon_config() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['coupon_config'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['coupon_config']
            }
            return ''
        },
        calculator_color() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['calculator_color'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['calculator_color']
            }
            return 'yellow'
        },
        employer_grant() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['employer_grant'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['employer_grant']
            }

            return '{"protection":{"value":1,"disabled":false},"leasing":{"value":0,"disabled":false},"package":{"value":0,"disabled":false},"tax":{"value":0,"disabled":false}}'
        },
        color_button() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['color_button'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['color_button']
            }
            return '{"color":{"background":"#ffd400","text":"#000000"}}'
        },
        residual() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['residual'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['residual']
            }
            return null
        },
        grantType_config() {
            if (
                this.containsKey(this.givenSettings, this.settingType) &&
                this.givenSettings[this.settingType]['grantType_config'] !== undefined
            ) {
                return this.givenSettings[this.settingType]['grantType_config']
            }
            return ''
        },
    },
    data() {
        return {
            givenSettings: JSON.parse(this.settings),
            productType: '',
            settingType: '',
        }
    },
    props: {
        tabs: {
            default: '',
            type: String,
        },
        url: {
            type: String,
            default: '',
        },
        settings: {
            type: String,
            default: '',
        },
        portal: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style></style>
